import React from 'react';
import {useDispatch} from 'react-redux';

const ClearButton = () => {
  const dispatch = useDispatch()
  const restart = () => {
    dispatch({type: 'CLEARED_WORKSPACE'})
  }
  return (
    <div>
      <button onClick={restart}> Start over </button>
    </div>
  )

}

export default ClearButton
