import React, {useEffect, useState, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {Map, View, Feature} from 'ol'
import {Tile, Vector as VectorLayer} from 'ol/layer'
import {Vector as VectorSource, XYZ} from 'ol/source'
import {LineString, MultiLineString,} from 'ol/geom'
import {transform, fromLonLat} from 'ol/proj'
import {Style, Stroke} from 'ol/style'
import {Draw, Modify} from 'ol/interaction'
import 'ol/ol.css'
import './RTZMap.css'
import {GreatCircle} from 'arc'

const RTZMap = () =>{

  const route = useSelector(state => state.route)
  const operation = useSelector(state => state.operation)
  const dispatch = useDispatch()
  const [target, setTarget] = useState(false)
  const source = useState(new VectorSource({
    wrapX: false,
  }))

  const vector = useState(new VectorLayer({
    source: source[0],
  //  style: style1
  }))

  const map = useState(new Map({
    target: null,
    layers: [
      new Tile({
        source: new XYZ({
          attributions: '"Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL."',
          url: 'https://cartodb-basemaps-{a-c}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
        })
      }),
      new Tile({
        source: new XYZ({
          url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png'
        })
      }),
      vector[0]
    ],
    view: new View({
      center: fromLonLat([0, 50]),
      zoom: 4
    })
  }))

  const draw = useState(
    new Draw({
      source: source[0],
      type: "LineString",
      geometryName: 'Drawn'
    })
  )

  const modify = useState(
    new Modify({source: source[0]})
  )

  const mapRef = useRef(null)

  useEffect(() => {
    const getLegType = idx => {
      if (route[idx]) {
        return route[idx].legType || "Loxodrome"
      }
      return "Loxodrome"
    }

    let coordinates = WGS84_to_WebMercator(route)
    var rtz = new Feature({
      geometry: new LineString(coordinates),
      geometryName: 'rtz'
    })
    source[0].getFeatures().forEach( (feature) => {
      source[0].removeFeature(feature)
    })
    source[0].addFeature(rtz)

    const style1 = new Style({
      geometry: function(feature) {
        var projection = map[0].getView().getProjection();
        var geom = feature.getGeometry()
        var coords = [];
        let cnt = -1
        geom.forEachSegment( (start, end) => {
          let from = transform(start, projection, 'EPSG:4326')
          let to = transform(end, projection, 'EPSG:4326')
          cnt = cnt +1
          if (getLegType(cnt+1) === "Orthodrome") {
            var arcGenerator = new GreatCircle(
                  {x: from[0], y: from[1]},
                  {x: to[0], y: to[1]});
            var arcLine = arcGenerator.Arc(100, {offset: 10});
            arcLine.geometries.forEach(function(geom) { coords.push(geom.coords); })
          } else {
             coords.push([from, to])
          }
        })

        var line = new MultiLineString(coords);
        line.transform('EPSG:4326', projection);
        return line;
      },
      stroke: new Stroke({
        width: 2,
        color: 'red'
      })
    })

    if (!target) {

      map[0].setTarget(mapRef.current)
      setTarget(true)

      modify[0].on('modifyend', (evt) => {
        evt.features.forEach((feat) => {
          dispatch({
            type:'MODIFY_ROUTE',
            payload: WebMercator_to_WGS84(feat.getGeometry().getCoordinates())
          })
        })
      })

      draw[0].on('drawend', function(evt){
        map[0].removeInteraction(draw[0]);
        dispatch({
          type:'STOPPED_DRAWING',
          payload: WebMercator_to_WGS84(evt.feature.getGeometry().getCoordinates())
        })
      })
    }

    vector[0].setStyle(style1)
    if (operation==='view') {
      map[0].addInteraction(modify[0])
    }
    if (operation === 'load'){
      map[0].removeInteraction(draw[0])
      map[0].addInteraction(modify[0])
    }
    if (operation === 'drawing') {
      map[0].addInteraction(draw[0])
    }
  }, [route, source, target, vector, operation, map, modify, draw, dispatch])

  return(
    <div className="RTZMap" ref={mapRef} id="map">
    </div>
  )
}

export default RTZMap

class WayPoint  {
  constructor(pos) {
    this.pos = pos;
  }
}

const WebMercator_to_WGS84 = coords => {
  return coords.map(
    coordinate => new WayPoint( transform(
      coordinate,
      'EPSG:3857',
      'EPSG:4326',
  )))
}

const WGS84_to_WebMercator = coords => {
  return coords.map(
    wp => transform(
      wp.pos,
      'EPSG:4326',
      'EPSG:3857',
  ))
}
