import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css';
import './datasheet.css'


const WaypointSheet = () => {
  const dispatch = useDispatch()
  const route = useSelector(state => state.route)
  const columns = [{name:'lon'}, {name:'lat'}, {name:'legtype'}]

  const dataConverter = route => {
    let data = []
    route.forEach((row, idrow) => {
      let column = [];
      row.forEach((col, idcol) => {
        if (typeof(col)==='number') {
          column.push({expression:col, value:`${col.toFixed(4)}`})
        } else {
          column.push({value:col, component: (
            <form>
              <input type="checkbox" value="Orthodrome"
                defaultChecked={col==="Orthodrome"}
              />
          </form>
          )})
        }
      });
      data.push(column)
    });
    return data
  }
  return (
    <ReactDataSheet
      data={dataConverter(route.map(wp => [...wp.pos, wp.legType]))}
      valueRenderer= {cell => cell.value}
      dataRenderer= {cell => cell.col===2 ? cell.value : parseFloat(cell.expression)}
      onCellsChanged={changes => {
        const temp_route = route
        changes.forEach(({cell, col, row, value}) => {
          switch(col) {
            case 0:
            case 1:
              temp_route[row].pos[col] = parseFloat(value)
              break
            case 2:
              if (cell.value==="Orthodrome") {
                temp_route[row].legType = "Loxodrome"

              } else {
                temp_route[row].legType = "Orthodrome"
              }
              break
            default:
          }
        })
        dispatch({
          type:'MODIFY_ROUTE',
          payload: temp_route,
          operation: 'edit'
        })
      }}
      sheetRenderer={props => (
        <table className={props.className + ' waypointlist'}>
          <thead>
            <tr>
              {columns.map((col, id) => (<th key={id}>{col.name}</th>))}
            </tr>
          </thead>
          <tbody>
            {props.children}
          </tbody>
        </table>
      )}
    />
  )
}


export default WaypointSheet
