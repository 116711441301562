import React from 'react'
import RTZMap from './components/RTZMap'
import MenuBar from './components/MenuBar'
import './App.css';

const App = () => {
  return (
    <div className="App">
      <RTZMap/>
      <MenuBar/>
    </div>
  );
}

export default App;
