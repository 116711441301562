import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {useDispatch} from 'react-redux';

const RTZDropZone = () => {
  const dispatch = useDispatch()
  const onDrop = useCallback(files => {
    const legType = (doc) => {
      return document.evaluate('.//@geometryType', doc, nsResolver, XPathResult.STRING_TYPE, null).stringValue
    }
    const filereader = new FileReader()
    const parser = new DOMParser();
    var result = [];
    filereader.onload = (evt) => {
      var doc = parser.parseFromString(evt.target.result, "application/xml");
      var default_type = document.evaluate('//default:defaultWaypoint/default:leg/@geometryType', doc, nsResolver, XPathResult.STRING_TYPE, null).stringValue || "Loxodrome"
      var positionIterator = document.evaluate('//default:waypoint', doc, nsResolver, XPathResult.ANY_TYPE, null)
      var wp = positionIterator.iterateNext()
      while (wp) {
        result.push({
          id: wp.getAttribute('id'),
          legType: legType(wp) || default_type,
          pos : [
            parseFloat(wp.getElementsByTagName('position')[0].getAttribute('lon')),
            parseFloat(wp.getElementsByTagName('position')[0].getAttribute('lat'))
          ]
        })
        wp = positionIterator.iterateNext()
      }
      dispatch({type: 'CHANGE_OPERATION', op: 'load', route: result})
    }
    files.forEach((f) => {
      filereader.readAsText(f)
    });
    // Do something with the files
  },[dispatch])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  const nsResolver = (prefix) => {
    return 'http://www.cirm.org/RTZ/1/0'
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
          <p className="box"> Drop a RTZ-file in the box, or click to select files</p>
    </div>
  )
}

export default RTZDropZone
