import {createStore} from 'redux';

const initialState = {
  route: [],
  operation: 'drawing'
}

const rootReducer = (state = initialState, action) =>  {
  switch (action.type) {
    case 'CLEARED_WORKSPACE':
      return {...state, operation:'drawing', route: []}
    case 'CHANGE_OPERATION':
      return {...state, operation: action.op, route: action.route};
    case 'MODIFY_ROUTE':
      var newRoute = []
      if (state.route.length === action.payload.length) {
        state.route.forEach((elem,id) => {
          newRoute.push({
            id: elem.id,
            legType: elem.legType,
            pos: action.payload[id].pos
          })
        })
      } else if (state.route.length > action.payload.length) {
        let memRoute = [...state.route]
        let guiRoute = [...action.payload]
        let m = memRoute.shift()
        let g = guiRoute.shift()
        while (g) {
         if (m.pos[0] === g.pos[0] && m.pos[1] === g.pos[1]) {
           newRoute.push({
             id: m.id,
             legType: m.legType,
             pos: m.pos
           })
           m = memRoute.shift()
           g = guiRoute.shift()
         } else {
           m = memRoute.shift()
         }
        }
      } else if (state.route.length < action.payload.length){
        let memRoute = [...state.route]
        let guiRoute = [...action.payload]
        let m = memRoute.shift()
        let g = guiRoute.shift()
        while (m) {
          if (m.pos[0] === g.pos[0] && m.pos[1] === g.pos[1]) {
            newRoute.push({
              id: m.id,
              legType: m.legType,
              pos: m.pos
            })
            m = memRoute.shift()
            g = guiRoute.shift()
          } else {
            newRoute.push({
              id: m.id+"+",
              legType: "Loxodrome",
              pos: g.pos
            })
            g = guiRoute.shift()
          }
        }
      }
      return {...state, route: newRoute};
    case 'STOPPED_DRAWING':
      return {...state, route:action.payload.map(pos=>Object.assign(pos, {id:0}, {legType:'Loxodrome'})), operation: 'view'};
    default:
      return state;
  }
};

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(): undefined
);

export {store};
