import React from 'react';
import {useSelector} from 'react-redux';
import {saveAs} from 'filesaver.js-npm';
import format from 'xml-formatter'

const RTZDownload = () => {
  const state_route = useSelector(state => state.route)
  const generateRTZ = () => {
    const namespace = 'http://www.cirm.org/RTZ/1/0'
    var xmlDoc = document.implementation.createDocument(
      "http://www.cirm.org/RTZ/1/0", "route");

    var info = xmlDoc.createElementNS(namespace, "routeInfo");
    info.setAttribute('routeAuthor', 'rtzeditor.com');
    info.setAttribute('routeName', 'anonymous');

    var route = xmlDoc.getElementsByTagName('route')
    route[0].setAttribute('version','1.0')
    route[0].appendChild(info)

    var waypoints = xmlDoc.createElementNS(namespace, 'waypoints');
    route[0].appendChild(waypoints)

    var defaultWaypoint = xmlDoc.createElementNS(namespace, 'defaultWaypoint')
    var defaultLeg = xmlDoc.createElementNS(namespace, 'leg')
    defaultLeg.setAttribute('draughtAft', '11.7')
    defaultLeg.setAttribute('draughtForward', '11.25')
    defaultLeg.setAttribute('geometryType', 'Loxodrome')
    defaultWaypoint.appendChild(defaultLeg)
    waypoints.appendChild(defaultWaypoint)

    var waypoint, position;
    state_route.forEach((wp, ix) => {
      var leg = xmlDoc.createElementNS(namespace, 'leg')
      if (wp.orthodrome) {
        leg.setAttribute('geometryType','Orthodrome')
      }
      waypoint = xmlDoc.createElementNS(namespace, 'waypoint')
      waypoint.setAttribute('id',ix)
      position = xmlDoc.createElementNS(namespace, 'position')
      position.setAttribute('lat', wp.pos[1])
      position.setAttribute('lon', wp.pos[0])
      waypoint.appendChild(position)
      waypoint.appendChild(leg)
      waypoints.appendChild(waypoint)
    })
    var oSerializer = new XMLSerializer();
    var sXML = format(oSerializer.serializeToString(xmlDoc));
    var blob = new Blob([sXML], {
      type: "plain/text;charset=utf-8",
    })
    saveAs(blob, "route.rtz")
  }

  return (
    <div>
      <button onClick={generateRTZ}> Download </button>
    </div>
  )
}

export default RTZDownload
