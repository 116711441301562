import React from 'react'
import RTZDownload from './download'
import RTZDropZone from './dropzone'
import WaypointSheet from './datasheet'
import ClearButton from './tools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faDownload, faUpload, faRoute } from '@fortawesome/free-solid-svg-icons'
import './MenuBar.css'
import 'react-datasheet/lib/react-datasheet.css'

const MenuBar = () => {
  const toggle = (evt) => {
    let tools = document.querySelector('.tools');
    tools.classList.toggle('tools--visible');
  }
  return (
    <div className="menubar">
      <div className="tools">
        <WaypointSheet/>
        <RTZDownload/>
        <RTZDropZone accept='.rtz'/>
        <ClearButton/>
      </div>
      <div className="control-group" onClick={toggle}>
        <FontAwesomeIcon icon={faBars} className="menuitem hamburger"/>
        <FontAwesomeIcon icon={faRoute} className="menuitem route"/>
        <FontAwesomeIcon icon={faDownload} className="menuitem download"/>
        <FontAwesomeIcon icon={faUpload} className="menuitem upload"/>
        <div className="control-space"> <p> RTZ-editor </p> </div>
      </div>
    </div>
  )
}

export default MenuBar
